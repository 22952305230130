export class TransTypeAcro {
  static payment: number; // 'Payment',
  static fee: number; // 'Fee',
  static cfee: number; // 'Contract Fee',
  static dtm: number; // 'Deposit To Merchant',
  static dto: number; // 'Deposit To Outstanding',
  static dcon: number; // 'Deposit Consolidate',
  static deposit: number; // 'Deposit',
  static withdrawal: number; // 'Withdrawal',
  static commission: number; // 'Commission',
  static feeMgmt: number; // 'Mgmt Fee',
  static settlement: number; // 'Settlement',
  static refund: number; // 'Refund',
  static tti: number; // 'TTI',
  static tfi: number; // 'TFI',
  static dflt: number; // 'Default',
  static discount: number; // 'Discount',
  static syndFee: number; // 'Synd Fee'
  static recall: number; // 'Synd Fee'
  static nonAchPayment: number; // 'Synd Fee'
  static feeWaiver: number; // 'Synd Fee'
  static placeholder: number; // 'Synd Fee'
}

export class TransSubTypeAcro {
  static reSched: number; // 'ReScheduled',
  static maf: number; // 'Month Admin Fee',
  static fp: number; // 'Failed Payment',
  static ffp: number; // 'Failed Fee Payment',
  static nsfFee: number; // 'NSF Fee',
  static commission: number; // 'NSF Fee',
  static attorneyFee: number;
  static loan: number;
  static interest: number;
  static principal: number;
  static invoice: number;
}

export class TransStatusAcro {
  static prep: number; // 'Prepared',
  static sched: number; // 'Scheduled',
  static sent: number; // 'Sent',
  static hold: number; // 'Hold',
  static suspended: number; // 'Suspended',
  static reSched: number; // 'ReScheduled', // inactive - rescheduled into another transaction, result of file.
  static failed: number; // 'Failed',
  static canceled: number; // 'Canceled',
  static returned: number; // 'Returned',
  static processed: number; // 'Processed',
  static pending: number; // 'Pending',
  static manual: number; // 'Manual',
  static allocated: number; // 'Allocated'
}

export const transDefenitions = {
  transActiveStatus: undefined as undefined | number[],
  transPaymentActiveStatus: undefined as undefined | number[],

  isPaymentProcessed(stat?: number): boolean {
    return ((stat && this.transPaymentActiveStatus?.indexOf(stat)) ?? -1) >= 0;
  },

  isTransactionProcessed(stat?: number): boolean {
    return ((stat && this.transActiveStatus?.indexOf(stat)) ?? -1) >= 0;
  },
};
