export class SettlementCommonData {
  settlement_balance?: number = undefined;
  frequency?: number = undefined;
  payment?: number = undefined;
}

export interface SettlementDurationInfo {
  months_number: number;
  last_payment_date: Date;
  last_payment_message: string;
}

export interface SettlementAttributes extends SettlementCommonData {
  months_number: number;
  last_payment_message: string;
}

export interface SettlementDurationInfo {
  months_number: number;
  last_payment_date: Date;
  last_payment_message: string;
}

export class SettlementRequestParameters extends SettlementCommonData {
  active_balance = 0;
  contract_balance = 0;
  discount = 0;
  effective_fa = 0;
  sent_or_scheduled_payments = 0;
  pending_payments = 0;
  prepared_payments = 0;
  missing_payments = 0;
  sent_commissions = 0;
  exposure_profit = 0;
}

export class SettlementEventData extends SettlementCommonData implements SettlementDurationInfo {
  months_number = 0;
  last_payment_date = new Date();
  last_payment_message = '';
}

export class SettlementFormData extends SettlementEventData {
  comment = '';
}
