import { SelectItem } from 'primeng/api';
import { TransactionAssignment, TransRec } from '@mca/shared/domain';

export class TransConstants {
  transStatuses: { [id: number]: string } = {};
  transTypes: { [id: number]: string } = {};
  transSubTypes: { [id: number]: { id: number; name: string; def: number } } = {};
  transVenues: string[] = [];
  ccVenues: string[] = [];
  ccVenuesFeeRates: { feeRate: number; merchantFeeRate: number; name: string }[] = [];
}

export type TransactionCollector = 'ext_collector' | 'int_collector' | 'ext_lawyer';

export const transactionCollectorOptions: SelectItem<TransactionCollector | null>[] = [
  { value: null, label: '' },
  { value: 'int_collector', label: 'Internal collection' },
  { value: 'ext_lawyer', label: 'External lawyer' },
  { value: 'ext_collector', label: 'External collection' },
];

export enum TransferType {
  'in_src' = 'in_src',
  'in_dst' = 'in_dst',
  'in_src_or_dst' = 'in_src_or_dst',
  'not_in_src_or_dst' = 'not_in_src_or_dst',
}

export const transferOptions: SelectItem<string | null>[] = [
  { value: null, label: 'All transactions' },
  { value: TransferType.in_src, label: 'Transfer from only' },
  { value: TransferType.in_dst, label: 'Transfer to only' },
  { value: TransferType.in_src_or_dst, label: 'Transfers' },
  { value: TransferType.not_in_src_or_dst, label: 'Exclude any participant of transfers' },
];

export class Transactions implements TransRec {
  id = 0;
  userid = 0;
  mcaid = 0;
  mcaref = 0;
  loan_id?: number;
  loan_issuer_id?: number;
  externkey?: string;
  transtype?: number;
  transsubtype = 0;
  status?: number;
  externstatus?: string;
  ammount = 0;
  account = '';
  routing = '';
  acctname?: string;
  accttype = 1;
  comment?: string;
  error?: string;
  effectivedate: Date = new Date();
  update_timestamp: Date = new Date();
  create_timestamp: Date = new Date();
  update_user = '';
  create_user = '';
  allocation_tag = '';

  venue = 'ACH';
  dealvenue = 0;
  processing_venue = 0;
  samedayach = false;

  assigned?: TransactionAssignment;

  related?: number[];

  static convertEffectiveDate(date: Date): Date {
    return new Date(date);
  }
}

export interface TransactionRef {
  ammount: number;
  comment: string;
  date: string;
  mca: number;
  mcaname: string;
  refid: number;
  transsubtype: number;
  transtype: string;
  user: string;
}

export class FeePayments {
  status = 0;
  effectivedate = new Date();
  venue = 'ACH';
  comment = '';
}

export class TransactionsDisp extends Transactions {
  userName = '';
}
